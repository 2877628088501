@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0&family=Ibarra+Real+Nova:wght@200;400;700&family=Inter:wght@400;700&family=JetBrains+Mono&display=swap");

:root {
  color-scheme: light;
  --font-family-base: Inter, sans-serif;
  --font-family-heading: "Ibarra Real Nova", serif;
  --font-family-monospace: "JetBrains Mono", monospace;
  --font-size-base: 20px;
  --color-base: lightblue;
  --color-contrast: lightgreen;
  --color-background: white;
  --color-text: black;
  --size-base: 5px;
  --size-md: calc(2 * var(--size-base));
  --size-lg: calc(4 * var(--size-base));
  --size-thin: 1px;
  --size-thick: 2px;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --color-base: darkblue;
    --color-contrast: darkgreen;
    --color-background: black;
    --color-text: white;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: var(--font-size-base);
  font-family: var(--font-family-base);
}

h1,
h2 {
  font-family: var(--font-family-heading);
  margin: 0;
  padding: var(--size-md) 0;
}

header {
  --border-bottom-color: color-mix(in srgb, var(--color-contrast) 85%, black);
  background: var(--color-contrast);
  border-bottom: var(--size-thick) solid var(--border-bottom-color);
  margin-bottom: var(--size-base);
}

header nav ul {
  list-style: none;
  margin: 0;
}

code,
pre {
  font-family: var(--font-family-monospace);
}

pre {
  white-space: pre;
}


